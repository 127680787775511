/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cooper-confirm .cooper-upload-hint-box .ant-checkbox-checked .ant-checkbox-inner,
.cooper-confirm .cooper-upload-hint-box .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fefefe;
  border-color: #bec5d2;
  border-radius: 3px;
}
.cooper-confirm .cooper-upload-hint-box {
  margin-bottom: 18px;
  margin-top: 12px;
}
.cooper-confirm .cooper-upload-hint-box:hover .ant-checkbox-inner,
.cooper-confirm .cooper-upload-hint-box .cooper-confirm .ant-checkbox:hover .ant-checkbox-inner,
.cooper-confirm .cooper-upload-hint-box .cooper-confirm .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0066FF;
}
.cooper-confirm .cooper-upload-hint-box + span,
.cooper-confirm .ant-checkbox + span {
  color: #2F343C;
  padding-left: 10px;
  font-size: 14px;
}
.cooper-confirm .cooper-upload-hint-box .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}
.cooper-confirm .cooper-upload-hint-box .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 2px;
  top: 2px;
  border: 0;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuWbvuWxgl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTAgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwIDEwOyBmaWxsOiMwMDY2RkYiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8dGl0bGU+aWNvbl9nb3V4dWFuPC90aXRsZT4KPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CjxwYXRoIGQ9Ik05LjIsMS44Yy0wLjMtMC4zLTAuOS0wLjMtMS4yLDBMMy4yLDYuMkwyLjEsNC40QzEuOSw0LDEuNCwzLjksMC45LDQuMUMwLjcsNC4yLDAuNiw0LjQsMC41LDQuNmMtMC4xLDAuMiwwLDAuNSwwLjEsMC43CglMMi4zLDhjMC4xLDAuMiwwLjQsMC40LDAuNiwwLjRsMC4xLDBjMC4yLDAsMC41LTAuMSwwLjYtMC4zTDkuMiwzYzAuMi0wLjIsMC4zLTAuNCwwLjMtMC42QzkuNCwyLjEsOS4zLDEuOSw5LjIsMS44eiIvPgo8L3N2Zz4K);
  background-size: 10px 10px;
  transform: rotate(0deg) scale(1);
}
.cooper-confirm .cooper-upload-hint-box .ant-checkbox-input:focus + .ant-checkbox-inner {
  background-color: #fefefe;
  border-color: #bec5d2;
  border-radius: 3px;
}
.cooper-upload-list-up .cooper-upload-list-open {
  display: block;
}
.cooper-upload-list-up .cooper-upload-list-close {
  display: none;
}
.cooper-upload-list-up .drag-modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 36px;
  display: none;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 4px dashed #fff;
}
.cooper-upload-list-up .cooper-upload-list {
  position: fixed;
  bottom: 12px;
  right: 52px;
  background-color: #fff;
  width: 480px;
  border: 1px solid rgba(148, 160, 176, 0.14);
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0 rgba(47, 52, 60, 0.2);
  border-radius: 4px;
  z-index: 20;
}
.cooper-upload-list-up .cooper-upload-list .header {
  position: relative;
  height: 58px;
  width: 100%;
  box-sizing: border-box;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 58px;
  width: 100%;
  padding: 0 24px;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .desc {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .desc .failed {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  margin-right: 8px;
  background-image: url(./icon/failed.svg);
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .desc .success {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  margin-right: 8px;
  background-image: url(./icon/success.svg);
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .desc .paused {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  margin-right: 8px;
  background-image: url(./icon/async.svg);
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .desc .async {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  margin-right: 8px;
  background-image: url(./icon/async.svg);
  animation: turn 2s linear infinite;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .desc .progress {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  white-space: nowrap;
  color: #2F343C;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .close {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  margin-left: 24px;
  background-image: url(./icon/close.svg);
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .pointer {
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .pointer:hover {
  background-image: url(./icon/close-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .not-allowed {
  cursor: not-allowed;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .expand {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-image: url(./icon/arror.svg);
  transform: rotate(180deg);
  -webkit-transition: transform 0.25s linear;
  -moz-transition: transform 0.25s linear;
  -o-transition: transform 0.25s linear;
  transition: transform 0.25s linear;
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .expand:hover {
  background-image: url(./icon/arror-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .minimize {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-image: url(./icon/arror.svg);
  cursor: pointer;
  -webkit-transition: transform 0.25s linear;
  -moz-transition: transform 0.25s linear;
  -o-transition: transform 0.25s linear;
  transition: transform 0.25s linear;
}
.cooper-upload-list-up .cooper-upload-list .header .header-c .operation .minimize:hover {
  background-image: url(./icon/arror-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .header .progress-line {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
}
.cooper-upload-list-up .cooper-upload-list .header .progress-line .cooper-list-header-progress {
  position: absolute;
  left: 0;
  bottom: 0;
}
.cooper-upload-list-up .cooper-upload-list .header .progress-line .cooper-list-header-progress div {
  line-height: 2px !important;
  height: 2px !important;
}
.cooper-upload-list-up .cooper-upload-list .header .progress-line .cooper-list-header-progress .ant-progress-outer {
  display: block;
}
.cooper-upload-list-up .cooper-upload-list .header .progress-line .cooper-list-header-progress .ant-progress-outer .ant-progress-inner {
  position: absolute;
  left: 0;
  bottom: 0;
}
.cooper-upload-list-up .cooper-upload-list .header .progress-line .cooper-list-header-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background-color: #0066FF;
}
.cooper-upload-list-up .cooper-upload-list .body-open {
  height: 260px;
  transition: height 0.25s linear;
  -webkit-transition: height 0.25s linear;
  -moz-transition: height 0.25s linear;
  -o-transition: height 0.25s linear;
}
.cooper-upload-list-up .cooper-upload-list .body-close {
  height: 0px;
  transition: height 0.25s linear;
  -webkit-transition: height 0.25s linear;
  -moz-transition: height 0.25s linear;
  -o-transition: height 0.25s linear;
}
.cooper-upload-list-up .cooper-upload-list .body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.cooper-upload-list-up .cooper-upload-list .body .loading-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 92px;
}
.cooper-upload-list-up .cooper-upload-list .body .loading-c .loading-c-img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  animation: turn 2s linear infinite;
}
.cooper-upload-list-up .cooper-upload-list .body .loading-c .loading-c-text {
  font-size: 14px;
  color: #94A0B0;
}
.cooper-upload-list-up .cooper-upload-list .body .list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 26px;
  background-color: #F5F6FA;
  border-top: 1px solid #e5e6ec;
}
.cooper-upload-list-up .cooper-upload-list .body .list-header .header-btn {
  cursor: pointer;
  height: 26px;
  font-size: 12px;
  color: #0066FF;
  margin-right: 24px;
  line-height: 26px;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items {
  overflow: auto;
  height: 100%;
  flex-direction: column;
  width: 100%;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  border-bottom: 1px solid rgba(148, 160, 176, 0.14);
  box-sizing: border-box;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .title-container {
  padding: 0;
  padding-left: 27px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 58px;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .title-container img {
  width: 26px;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .title-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 19px;
  width: 1px;
  flex: 1;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .title-container .text-container .name {
  font-size: 14px;
  color: #2F343C;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .title-container .text-container .hint-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .title-container .text-container .hint-container .size {
  font-size: 12px;
  color: #94A0B0;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .title-container .text-container .hint-container .hint {
  font-size: 12px;
  color: #ff3f5a;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .hint {
  font-size: 12px;
  color: #94A0B0;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .pause {
  background-size: 20px 20px;
  background-image: url(./icon/pause.svg);
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .pause:hover {
  background-image: url(./icon/pause-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .continue {
  background-size: 20px 20px;
  background-image: url(./icon/continue.svg);
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .continue:hover {
  background-image: url(./icon/continue-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .cancel {
  background-size: 20px 20px;
  background-image: url(./icon/cancel.svg);
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .cancel:hover {
  background-image: url(./icon/cancel-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .retry {
  background-size: 20px 20px;
  background-image: url(./icon/retry.svg);
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .retry:hover {
  background-image: url(./icon/retry-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .jumpto {
  background-size: 20px 20px;
  background-image: url(./icon/jumpto.svg);
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .jumpto:hover {
  background-image: url(./icon/jumpto-hover.svg);
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .failed {
  background-size: 20px 20px;
  background-image: url(./icon/failed.svg);
  cursor: pointer;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .failed:hover {
  background-image: url(./icon/retry.svg);
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .success {
  background-size: 20px 20px;
  background-image: url(./icon/success.svg);
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .icon {
  width: 20px;
  height: 20px;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .one {
  margin-right: 8px;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .operation .two {
  margin-right: 20px;
}
.cooper-upload-list-up .cooper-upload-list .body .list-items .item-container .progress {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.upload-big-file-tip .ant-confirm-title {
  font-size: 20px;
  line-height: 26px;
}
.upload-big-file-tip .ant-confirm-content {
  font-size: 14px;
}
