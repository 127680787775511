/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.icon-user {
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6A707C;
  margin-right: 18px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  background-size: cover;
}
.icon-user:hover {
  opacity: 0.8;
}
.icon-user-small {
  cursor: pointer;
  height: 28px;
  line-height: 40px;
  text-align: center;
  width: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #6A707C;
  margin-right: 0;
  margin-top: 5px;
  border-radius: 50%;
  background-size: contain;
}
.arrow-tenant {
  display: inline-block;
  height: 7px;
  width: 7px;
  background-image: url('http://img-ys011.didistatic.com/static/cooper_cn/do1_wHpQhmq96fzvH9ZKqAdD');
  background-repeat: no-repeat;
  background-size: contain;
}
.userInfoCard {
  border: 1px solid #eeeeef;
  border-radius: 4px;
  background-color: #ffffff;
}
.userInfoCard .ant-popover-inner {
  box-shadow: none !important;
}
.changeTenantList .ant-popover-inner {
  box-shadow: none !important;
}
.changeTenantList .ant-popover-inner-content {
  border: 1px solid #eeeeef !important;
  border-radius: 4px;
}
.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 54px;
}
.userInfo .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.userInfo .info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 10px;
}
.userInfo .info-name {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
  color: #222a35;
}
.userInfo .info-tenant {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #a7aaae;
}
.line {
  width: 216px;
  height: 1px;
  background: #f2f3f3;
  margin-bottom: 12px;
}
.tenant-change-label {
  position: relative;
  opacity: 0.1;
}
.tenant-change-label .tenant-change {
  position: absolute;
  top: 0;
  left: 0;
}
.changeTenantList .ant-popover-inner .ant-popover-inner-content {
  max-height: 95vh;
  overflow-y: scroll;
  width: 250px;
}
.changeTenantList .ant-popover-inner .ant-popover-inner-content li .tenantName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 190px;
}
.changeTenantList .ant-popover-inner .ant-popover-inner-content .choose-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.changeTenantList .ant-popover-inner .ant-popover-inner-content .choose-li .icon-selected {
  color: #076bff;
}
.userInfoCard .ant-popover-inner .ant-popover-inner-content {
  width: 240px;
}
.userInfoCard .userInfo .info-name,
.userInfoCard .info-tenant {
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
