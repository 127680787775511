/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-sync {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 40px;
  line-height: 32px;
  text-align: center;
  margin-top: 5px;
  margin-left: 24px;
  cursor: pointer;
}
.cooper-sync > img {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0.8;
}
.cooper-sync > img:hover {
  opacity: 1;
  transition: 0.1s;
}
