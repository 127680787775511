/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.v3-cooper-survey {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v3-cooper-survey img {
  width: 58px;
  height: 48px;
  padding-right: 18px;
  box-sizing: content-box;
}
.v3-cooper-survey-desc {
  color: #00A270 !important;
}
.cooper-survey-popover-window {
  padding-right: 0 !important;
}
.v3-cooper-survey-rightTop-icon {
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: -16px;
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
.v3-cooper-survey-rightTop-icon::before {
  display: none !important;
}
.v3-cooper-survey-rightTop-icon:hover {
  color: rgba(0, 0, 0, 0.3) !important;
}
.cooper-survey-popover .ant-popover-inner {
  background: #E9FDF7 !important;
  height: 46px !important;
  border-radius: 42px 0 0 42px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 34px !important;
  padding: 0 26px 0 16px !important;
  margin-right: -40px;
  margin-top: 1px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1) !important;
}
.cooper-survey-popover .ant-popover-inner-content {
  padding: 6px 0 !important;
}
.cooper-survey-popover-window .ant-popover-inner {
  font-weight: normal !important;
}
