/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.pop-content {
  width: 144px;
}
.pop-content .item-name {
  height: 34px;
}
.pop-content .split-line {
  width: 100%;
  height: 1px;
  background: #EBEEF1;
  margin: 8px 0;
}
.pop-content .more-title {
  font-size: 12px;
  margin-bottom: 8px;
  padding-left: 12px;
  color: rgba(34, 42, 53, 0.5);
}
.more-system-popover {
  padding-left: 8px!important;
}
