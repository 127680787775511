/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.logo .logo-large {
  width: 158px;
  margin: 10px 16px 0px;
  padding: 0 12px;
}
.logo .logo-small {
  width: 36px;
  margin: 10px 8px;
  position: relative;
  left: 4px;
}
