/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.no-width {
  width: 0;
  opacity: 0;
}
.large-wrap .aside-large {
  width: 240px;
}
.large-wrap .aside-large .item-name-normal {
  padding: 7px 24px;
}
.large-wrap .aside-large .item-name {
  height: 34px;
}
.large-wrap .aside-large .split-line {
  width: 100%;
  height: 1px;
  background-color: #EBEEF1;
  margin-top: 4px;
  margin-bottom: 6px;
}
.large-wrap .pine-space {
  width: 100%;
  padding: 0 8px;
}
.large-wrap .pine-space :global .space-item-nav {
  padding-left: 46px !important;
}
