/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.aside-skeleton-box {
  width: 240px;
  padding: 0px 8px;
}
.aside-skeleton-box .aside-skeleton-button * {
  min-width: auto !important;
  width: 100% !important;
  height: 100% !important;
}
.aside-skeleton-box .aside-skeleton-box-item {
  width: 100%;
  height: 36px;
  position: relative;
}
.aside-skeleton-box .aside-skeleton-box-item .aside-skeleton-logo {
  position: absolute;
  top: 10px;
  left: 24px;
  width: 16px;
  height: 16px;
}
.aside-skeleton-box .aside-skeleton-box-item .aside-skeleton-title {
  position: absolute;
  top: 11px;
  left: 52px;
  width: 48px;
  height: 14px;
}
.aside-skeleton-box .aside-skeleton-space {
  width: 100%;
}
.aside-skeleton-box .aside-skeleton-space .aside-skeleton-space-item {
  width: 100%;
  height: 35px;
  position: relative;
}
.aside-skeleton-box .aside-skeleton-space .aside-skeleton-space-item .aside-skeleton-symbol {
  display: block;
  position: absolute;
  top: 15.5px;
  left: 52px;
  width: 4px;
  height: 4px;
}
.aside-skeleton-box .aside-skeleton-space .aside-skeleton-space-item .aside-skeleton-title {
  display: block;
  position: absolute;
  top: 11px;
  left: 64px;
  width: 88px;
  height: 13px;
}
