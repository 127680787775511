// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.icon-user {
  text-align: center;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: @blueGray-15;
  margin-right: 18px;
  // background-color: pink;
  background-repeat: no-repeat;
  // background-size: 100%;
  background-position: center;
  border-radius: 50%;
  background-size: cover;

  &:hover {
    opacity: 0.8;
  }
}
.icon-user-small {
  text-align: center;
  cursor: pointer;
  height: 28px;
  line-height: 40px;
  text-align: center;
  width: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: @blueGray-15;
  margin-right: 0;
  margin-top: 5px;
  border-radius: 50%;
  background-size: contain;
}
.arrow-tenant {
  display: inline-block;
  height: 7px;
  width: 7px;
  background-image: url('http://img-ys011.didistatic.com/static/cooper_cn/do1_wHpQhmq96fzvH9ZKqAdD');
  background-repeat: no-repeat;
  background-size: contain;
}
.userInfoCard {
  border: 1px solid rgb(238, 238, 239);
  border-radius: 4px;
  background-color: #ffffff;
}
.userInfoCard .ant-popover-inner {
  box-shadow: none !important;
}
.changeTenantList .ant-popover-inner {
  box-shadow: none !important;
}
.changeTenantList .ant-popover-inner-content {
  border: 1px solid rgb(238, 238, 239) !important;
  border-radius: 4px;
}
.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 54px;
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 10px;
  }
  .info-name {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0px;
    color: #222a35;
  }
  .info-tenant {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #a7aaae;
  }
}
.line {
  width: 216px;
  height: 1px;
  background: #f2f3f3;
  margin-bottom: 12px;
}
.tenant-change-label {
  position: relative;
  opacity: 0.1;
  .tenant-change {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.changeTenantList {
  .ant-popover-inner .ant-popover-inner-content {
    max-height: 95vh;
    overflow-y: scroll;
    // margin-top: 40px;
    width: 250px;
    li {
      .tenantName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 190px;
      }
    }
    .choose-li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-selected {
        color: #076bff;
      }
    }
  }
}
.userInfoCard {
  .ant-popover-inner .ant-popover-inner-content {
    width: 240px;
  }
  .userInfo .info-name,.info-tenant {
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
