/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.block-editor-guide-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.block-editor-guide-dialog .block-editor-guid-content {
  position: absolute;
}
.block-editor-guide-dialog .block-close {
  position: absolute;
  top: 10px;
  right: 16px;
  line-height: 1;
  cursor: pointer;
}
.block-editor-guide-dialog .block-close:hover .close-icon {
  color: #047FFE;
}
.block-editor-guide-dialog .block-close .close-icon {
  color: #505050;
  font-size: 12px;
}
.block-editor-guide-dialog .guide-container {
  width: 540px;
  padding: 24px;
  background: url("./background.png") no-repeat center;
  background-size: cover;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
}
.block-editor-guide-dialog .guide-container .guide-title {
  text-align: center;
  height: 30px;
  font-size: 22px;
  font-weight: 500;
  color: #111111;
  line-height: 30px;
}
.block-editor-guide-dialog .guide-container .guide-demo {
  width: 492px;
  height: 264px;
  overflow: hidden;
  border-radius: 4px;
  margin: 12px 0;
}
.block-editor-guide-dialog .guide-container .guide-demo .guide-img {
  width: 100%;
  object-fit: cover;
}
.block-editor-guide-dialog .guide-container .guide-summary {
  font-size: 14px;
  color: #333333;
  line-height: 22px;
}
.block-editor-guide-dialog .guide-container .guide-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.block-editor-guide-dialog .guide-container .guide-footer .guide-percent {
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}
.block-editor-guide-dialog .guide-container .guide-footer .guide-operation .guide-previous {
  color: #505050;
  padding: 7px 16px;
  background: #F4F4F4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.block-editor-guide-dialog .guide-container .guide-footer .guide-operation .guide-next {
  color: #FFFFFF;
  padding: 7px 16px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}
.novice-guide {
  position: relative;
}
.novice-guide .iconfont {
  color: #252525;
  font-size: 18px;
}
.novice-guide:hover .iconfont {
  color: #047FFE;
}
.novice-guide-tooltip {
  position: absolute;
  bottom: 100%;
  right: 0;
  transform: translateY(-10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 222px;
  height: 36px;
  font-size: 14px;
  color: #FFFFFF;
  padding: 8px 14px;
  background: #047FFE;
  white-space: nowrap;
  border-radius: 4px;
}
.novice-guide-tooltip .iconfont {
  color: #FFFFFF;
  font-size: 18px;
  cursor: pointer;
}
.novice-guide-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 14px;
  width: 12px;
  height: 6px;
  background: #047FFE;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
