/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.no-width {
  width: 0;
  opacity: 0;
}
.aside-small-box {
  width: 64px;
  flex: 1;
}
.aside-small {
  height: 100%;
  padding: 10px 2px 120px 2px;
}
.space-content-popover {
  padding-left: 4px !important;
}
.space-content-popover :global li {
  height: auto !important;
  padding: 0 !important;
}
.space-content-popover :global li:hover {
  background: none !important;
}
.space-content-popover :global .ant-popover-inner-content {
  padding: 8px !important;
}
.item-name {
  margin-bottom: 6px !important;
}
.item-name-normal {
  height: auto !important;
  padding: 4px 0 !important;
}
.small-name {
  font-size: 22px !important;
  transform: scale(0.5);
}
.title-space {
  display: inline-flex;
  align-items: center;
  padding-left: 18px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 0 12px;
  cursor: pointer;
}
.title-space .title-text {
  font-size: 13px;
  line-height: 21px;
  color: rgba(34, 42, 53, 0.5);
}
.title-space:hover {
  background: #EBEEF1;
}
.popover-space {
  width: 228px;
}
@media screen and (max-height: 726px) {
  :global .windows .aside-small-box-media li {
    margin-bottom: 0 !important;
  }
}
