/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.header-warp {
  padding: 0 32px;
  margin: 0 auto;
  min-width: 667px;
}
.header-warp .header-top {
  margin: 0 auto;
  min-width: 667px;
  height: 72px;
  line-height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-warp .header-top .title-opt {
  display: flex;
  flex-grow: 1;
  justify-content: right;
  min-width: 192px;
  text-align: right;
  margin-left: 8px;
}
.header-warp .header-top .title-opt > button {
  margin-left: 12px;
}
.header-warp .header-top .title-opt.title-opt-with-create-space {
  min-width: 0;
}
.header-warp .header-top .header-right {
  display: flex;
  align-items: center;
}
.dc-header-warp {
  padding: 0 24px;
}
