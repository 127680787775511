/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.icon-shiyanshi {
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6A707C;
}
.icon-shiyanshi:hover {
  background: #EBEEF1;
}
.icon-shiyanshi-small {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #6A707C;
}
.library-shiyanshi-tooltip.ant-tooltip-placement-right .ant-tooltip-content {
  transform: translateX(8px);
}
