// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




.cooper-notify-popover {
  .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-popover-inner-content li {
    height: auto !important;
  }
}

.cooper-notify-icon {
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  // width: 24px;
  text-align: center;
  // height: 24px;
  // line-height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  &:hover {
    background: #EBEEF1;
  }
  .dk-icon-zhanneixin {
    font-size: 16px;
    color: @blueGray-15;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}
.notify-list-item-detail-desc {
  .title-con {
    width: 87%;
    word-break: break-word;
  }
}

.cooper-notify > img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 4px;
}

.cooper-notify-position {
  position: relative;
}

.cooper-notify-count {
  position: absolute;
  top: -6px;
  left: 10px;
  background-color: #ff563a;
  border: 1px solid #ffffff;
  color: #fff;
  display: inline-block;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  padding: 0 4px;
  z-index: 100;
}

.cooper-notify .notify-list {
  width: 400px;
  height: 435px;
  // position: absolute;
  bottom: 32px;
  left: 0px;
  background-color: #fff;
  z-index: 999;
  text-align: left;
  // margin-left: 8px;
  border-radius: 8px;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: -4px;
  //   left: 48px;
  //   width: 10px;
  //   height: 10px;
  //   background-color: #fff;
  //   box-shadow: -4px -4px 12px 0 rgba(0, 0, 0, .15);
  //   transform: rotate(45deg);
  //   z-index: 9999;
  // }

  &.hide {
    display: none;
  }

  &-box {
    width: 400px;
    border-radius: 8px;
    // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .15);
  }

  &-header {
    display: flex;
    line-height: 40px;
    padding: 0 16px;
    color: #8a93a8;

    :nth-child(1) {
      margin-right: 40px;
    }

    &-ceil {
      position: relative;
      cursor: pointer;
      user-select: none;
      &::before {
        content: '';
        display: none;
        position: absolute;
        left: 50%;
        bottom: -1px;
        width: 14px;
        border-bottom: 1px solid #fff;
        transform: translateX(-50%);
      }
      &::after {
        content: '';
        display: none;
        position: absolute;
        left: 50%;
        bottom: -5px;
        width: 10px;
        height: 10px;
        border-top: 1px solid #e4e9f3;
        border-right: 1px solid #e4e9f3;
        transform: translateX(-50%) rotate(-45deg);
      }
      &:not(.disabled):hover {
        font-weight: bold;
        color: #17233e;
      }

      &.active {
        font-weight: bold;
        color: #17233e;
        &::after, &::before {
          display: block;
        }
      }
      &.disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }

    &-allAsRead {
      background-color: #fff;
      border: none;
      margin-left: auto;
      cursor: pointer;
      &:hover {
        color: #0066FF;
      }
      &:focus {
        outline: none;
      }
      &.hide {
        display: none;
      }
    }
  }

  &-body {
    overflow: hidden;
    width: 100%;
    height: 393px;
    border-top: 1px solid #e4e9f3;
    word-break: break-all;

    &-box {
      padding-top: 4px;
    }

    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #bec5d2;
      &-box {
        text-align: center;
      }
      &-icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        // background: url('../../common/images/smile@1x.png') no-repeat center center;
        background-size: cover;
      }
    }

    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      li {
        list-style: none;
      }
    }
  }
  .notify-list-item {
    .notice-bg-tips {
      background: #fde093;
      padding: 4px 6px;
      border-radius: 2px;
      margin-top: 5px;
    }
    .bold {
      font-weight: 600;
    }
  }

  &-item {
    padding: 0 16px;
    &:not(:first-child) {
      .notify-list-item-box {
        border-top: 1px solid #e4e9f3;
      }
    }
    &:hover {
      background-color: rgba(63, 129, 255, .06) !important;
    }
    &.unread {
      background-color: #e4e9f330;
    }
    &-active {
      color: #0066FF;
    }
    &-box {
      padding: 12px 0;
    }
    &-main {
      display: flex;
    }
    &-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      img {
        position: relative;
        width: 100%;
        min-height: 100%;
        font-size: 0;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #eee;
        }
      }
    }
    &-detail {
      flex: 1;
      margin-left: 8px;
      font-size: 12px;
      &-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        line-height: 16px;
        color: #8a93a8;
        &-left {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          word-wrap: break-word;
          white-space: nowrap;
          max-width: 300px;
          // .tag-global {
          //   flex: 1;
          // }
        }
      }
      &-desc {
        line-height: 16px;
      }
      &-name {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        word-wrap: break-word;
        white-space: nowrap;
        max-width: 120px;
        a {
          color: inherit;
        }
      }
      &-department {
        display: inline-block;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        word-wrap: break-word;
        white-space: nowrap;
      }
      &-time {
        margin-left: 10px;
        flex: 0 0 auto;
      }
      &-text-area {
        margin: 8px 0 22px 0;
        border-radius: 2px;
        > textarea {
          font-size: 14px;
          border-radius: 2px;
          background-color: @blueGray-12 !important;
        }
      }
    }

    &-access-setting {
      margin-top: 8px;
      line-height: 16px;
      .ant-checkbox {
        &-wrapper + .ant-checkbox-wrapper {
          margin-left: 20px;
        }
        &-inner {
          border-color: #BEC5D2;
        }

        &-wrapper:hover .ant-checkbox-inner,
        &:hover .ant-checkbox-inner,
        &-input:focus + .ant-checkbox-inner,
        &-checked .ant-checkbox-inner,
        &-indeterminate .ant-checkbox-inner {
           border-color: #0066FF;
        }
        &-checked .ant-checkbox-inner,
        &-indeterminate .ant-checkbox-inner {
          background-color: #0066FF;
        }

        & + span {
          padding-left: 4px;
          padding-right: 0;
        }
        &-inner {
          border-radius: 50%;
        }
        &-checked::after {
          border-radius: 50%;
        }

        &-disabled {
          & + span {
            color: #8A93A8;
          }
          .ant-checkbox-inner {
            border-color: #BEC5D2 !important;
            background-color: #fff;
          }
          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: #BEC5D2;
              &::after {
                border-color: #fff;
              }
            }
          }
        }
      }
    }

    &-btns {
      margin-top: 12px;
      display: flex;
      justify-content: flex-end;
      line-height: 26px;
      a {
        border-radius: 4px;
        font-size: 12px;
      }
    }
    &-reject {
      padding: 1px 15px;
      border: 1px solid #e4e9f3;
      background-color: #fff;
      transition: all .3s;
      &:hover {
        color: @primary-color;
        border-color: @primary-color;
      }
    }
    &-accept {
      padding: 2px 16px;
      background-color: @primary-color;
      color: #fff;
      margin-left: 10px;
      transition: all .3s;
      &:hover {
        color: #fff;
        background-color: @primary-color;
      }
    }

    &-status {
      line-height: 24px;
      font-size: 12px;
      color: #8a93a8;
    }
  }

  &-loading {
    padding: 10px 0;
    text-align: center;
    color: #8a93a8;
    img {
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }
    span {
      margin-left: 4px;
      vertical-align: middle;
    }
  }
}
.notify-list-small .notify-list {
  left: 43px;
  bottom: 10px;
  &::before {
    width: 25px;
    height: 80px;
    left: -25px;
  }
 
}
.notify-list-small {
  // width: 40px;
  // height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 0px;
  margin-top: 5px;
  .dk-icon-zhanneixin {
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  > span {
    left: 18px;
    top: 2px;
  }
}
.notify-list-loading {
  justify-content: center;
}
// @media screen and (-webkit-min-device-pixel-ratio: 2) {
//   .cooper-notify {
//     .notify-list {
//       &-body {
//         &-empty {
//           &-icon {
//             // background-image: url('../../common/images/smile@2x.png');
//           }
//         }
//       }
//     }
//   }
// }

.notify-list-item-bold-name {
  font-weight: 600;
  color: @blueGray-1;
}