/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.global-utils-wrap {
  position: fixed;
  right: 24px;
  bottom: 40px;
  z-index: 1000;
}
.global-survey-wrap {
  position: fixed;
  right: 0px;
  top: 0;
  z-index: 1111;
}
