/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.tip-btn {
  position: relative;
  background: rgba(255, 165, 12, 0.1);
  border-radius: 4px;
  border: 0.5px solid rgba(255, 165, 12, 0.18);
  font-family: PingFangSC-Medium;
  font-size: 11px;
  font-weight: normal;
  color: #FAA006;
  display: flex;
  align-items: center;
  height: 24px;
  line-height: 24px;
  width: 70px;
  text-align: center;
  cursor: auto;
  overflow: hidden;
}
.tip-btn .cooper-tip-name {
  font-size: 22px;
  transform: scale(0.5);
  width: 100%;
  white-space: nowrap;
  transform-origin: left;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tip-btn .tip-content {
  display: none;
}
.tip-btn:hover {
  background: rgba(255, 165, 12, 0.2);
}
.tip-btn:hover .tip-content {
  display: flex;
}
.tip-btn > span {
  height: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tip-btn > span > img {
  width: 12px;
  height: 12px;
  margin-left: 2px;
}
.tip-btn-long {
  width: 92px;
}
.tip-btn-small {
  margin-left: 0;
  cursor: auto;
  width: 28px;
  height: 28px;
  background: rgba(255, 165, 12, 0.1);
  border: 0.7px solid rgba(255, 165, 12, 0.18);
  border-radius: 5.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.tip-btn-small .cooper-tip-name {
  display: none;
}
.tip-btn-small > span img {
  width: 17px;
  height: 17px;
  margin: 0;
}
.tip-btn-small:hover {
  background: rgba(255, 165, 12, 0.2);
}
.tip-btn-small:hover .tip-content {
  display: flex;
}
.tip-content {
  width: 258px;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 6px;
}
.tip-content .left {
  width: 24px;
  height: 100%;
  line-height: 20px;
  display: inline-block;
}
.tip-content .left img {
  width: 16px;
  height: 16px;
}
.tip-content .right {
  flex: 1;
  height: 100%;
  display: inline-block;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  color: #383F49 !important;
  line-height: 22px;
}
.bpm-link {
  padding: 0 2px;
  cursor: pointer;
  color: #0066FF;
}
