// 公共变量自动引入 ---- 自定义组件中可以使用vars
@import '~antd/lib/style/themes/default.less';
// 不设置的话，编译出来的css文件为空，部署不成功

// font

@font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Tahoma,Arial,"PingFang SC","Microsoft YaHei",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-semibold:800;

.font-size(@font-size:14px,@line-height:@font-size + 8) {
  font-size: @font-size;
  line-height: @line-height;
}

/** mixin **/
.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2() {
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp: 2; 
}

.widthAdaptation() {
  margin: 0 auto;
  // 最近表格不折行的宽度
  min-width: 667px;
}

.contentWidth() {
  min-width: 767px;
}

.editorTitle() {
  font-size: 36px!important;
  font-weight: 600!important;
  color: #333333!important;
  line-height: 50px;
  padding-top: 24px !important;
  cursor: auto;
}

@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

@border-radius-base: 2px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;
@border-radius-attach: 6px;

@primary-color: #047FFE;
@primary-1: #E5F2FE;
@primary-2: fade(@primary-color; 20%);
@primary-3: fade(@primary-color; 30%);
@primary-4: fade(@primary-color; 40%);
@primary-5: fade(@primary-color; 50%);
@primary-6: fade(@primary-color; 60%);
@primary-7: fade(@primary-color; 70%);
@primary-8: fade(@primary-color; 80%);
@primary-9: fade(@primary-color; 90%);
@primary-10: @primary-color;

@primary-color-hover: @primary-8;
@primary-color-active: @primary-color;
@primary-color-outline: @primary-color;

@primary-color-new: #1A6EFF;

@error-color: #FF563B;
@error-color-hover: fade(@error-color; 80%);
@error-color-active: @error-color;
@error-color-outline: @error-color;

@success-color: #2ECDA4;
@success-color-hover: fade(@success-color; 80%);
@success-color-active: @success-color;
@success-color-outline: @success-color;

@warning-color: #FFA50C;
@warning-color-hover: fade(@warning-color; 80%);
@warning-color-active: @warning-color;
@warning-color-outline: @warning-color;

@black: #000; // 命名为了和antd保持一致
@black-1: @black;
@black-2: fade(@black, 90%);
@black-3: fade(@black, 80%);
@black-4: fade(@black, 70%);
@black-5: fade(@black, 60%);
@black-6: fade(@black, 50%);
@black-7: fade(@black, 40%);
@black-8: fade(@black, 30%);
@black-9: fade(@black, 20%);
@black-10: fade(@black, 10%);

@white: #FFF; // 命名为了和antd保持一致

@blueGray-color: #222A35; 
@blueGray-1: @blueGray-color; 
@blueGray-2: fade(@blueGray-color, 90%);
@blueGray-3: fade(@blueGray-color, 80%);
@blueGray-4: fade(@blueGray-color, 70%);
@blueGray-5: fade(@blueGray-color, 60%); 
@blueGray-6: fade(@blueGray-color, 50%); 
@blueGray-7: fade(@blueGray-color, 40%);
@blueGray-8: fade(@blueGray-color, 30%);
@blueGray-9: fade(@blueGray-color, 20%); 
@blueGray-10: fade(@blueGray-color, 10%); 
@blueGray-11: fade(@blueGray-color, 8%); 
@blueGray-12: fade(@blueGray-color, 6%); 
@blueGray-13: fade(@blueGray-color, 4%); 
@blueGray-14: #F7F9FA; 
@blueGray-15: #6A707C; 
@blueGray-16: #EBEEF1; 
@blueGray-17: #D8DEE3; 
@blueGray-18: #E9EDF0;



@text-color: @blueGray-1; 
@text-color-secondary: @blueGray-5;

@border: @blueGray-11;
@background-grey: @blueGray-13;
@item-hover-bg: @blueGray-12;
@item-active-bg: @primary-1; // TODO:先定义，需要矫正

// Border color
@border-color-base: @blueGray-11; 
@border-color-split: @blueGray-11; 


// vertical paddings 
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//tabs
@tabs-horizontal-gutter: 32px;
@tabs-title-font-size: @font-size-lg;
@tabs-bar-margin:  0 0 @margin-md 0;
@tabs-horizontal-margin: 0 10px 0 @tabs-horizontal-gutter;
@tabs-highlight-color: @text-color;
@tabs-hover-color: @text-color;
@tabs-active-color: @text-color;




@keyframes turn {
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(360deg);
  }
}


.cooper-confirm {
  .cooper-upload-hint-box .ant-checkbox-checked .ant-checkbox-inner, .cooper-upload-hint-box .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #fefefe;
    border-color: #bec5d2;
    border-radius: 3px;
  }
  .cooper-upload-hint-box {
    margin-bottom: 18px;
    margin-top: 12px;
    &:hover .ant-checkbox-inner, .cooper-confirm .ant-checkbox:hover .ant-checkbox-inner, .cooper-confirm .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #0066FF;
    }
  }
  .cooper-upload-hint-box + span, .ant-checkbox + span {
    color: #2F343C;
    padding-left: 10px;
    font-size: 14px;
  }
  .cooper-upload-hint-box .ant-checkbox-inner {
    width: 16px;
    height: 16px;
  }
  .cooper-upload-hint-box .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 2px;
    top: 2px;
    border: 0;
    height: 10px;
    width: 10px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuWbvuWxgl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTAgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwIDEwOyBmaWxsOiMwMDY2RkYiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8dGl0bGU+aWNvbl9nb3V4dWFuPC90aXRsZT4KPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CjxwYXRoIGQ9Ik05LjIsMS44Yy0wLjMtMC4zLTAuOS0wLjMtMS4yLDBMMy4yLDYuMkwyLjEsNC40QzEuOSw0LDEuNCwzLjksMC45LDQuMUMwLjcsNC4yLDAuNiw0LjQsMC41LDQuNmMtMC4xLDAuMiwwLDAuNSwwLjEsMC43CglMMi4zLDhjMC4xLDAuMiwwLjQsMC40LDAuNiwwLjRsMC4xLDBjMC4yLDAsMC41LTAuMSwwLjYtMC4zTDkuMiwzYzAuMi0wLjIsMC4zLTAuNCwwLjMtMC42QzkuNCwyLjEsOS4zLDEuOSw5LjIsMS44eiIvPgo8L3N2Zz4K);
    background-size: 10px 10px;
    transform: rotate(0deg) scale(1);
  }
  .cooper-upload-hint-box .ant-checkbox-input:focus + .ant-checkbox-inner {
    background-color: #fefefe;
    border-color: #bec5d2;
    border-radius: 3px;
  }
}

.cooper-upload-list-up {
  .cooper-upload-list-open {
    display: block;
  }
  .cooper-upload-list-close {
    display: none;
  }
  .drag-modal {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 36px;
    display: none;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 4px dashed #fff;
  }
  .cooper-upload-list {
    position: fixed;
    bottom: 12px;
    right: 52px;
    background-color: #fff;
    width: 480px;
    border: 1px solid rgba(148,160,176,0.14);
    box-sizing: border-box;
    box-shadow: 0 4px 16px 0 rgba(47,52,60,0.2);
    border-radius: 4px;
    z-index: 20;
    .header {
      position: relative;
      height: 58px;
      width: 100%;
      box-sizing: border-box;
      .header-c {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 58px;
        width: 100%;
        padding: 0 24px;
        .desc {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .failed {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            margin-right: 8px;
            background-image: url(./icon/failed.svg);
          }
          .success {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            margin-right: 8px;
            background-image: url(./icon/success.svg);
          }
          .paused {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            margin-right: 8px;
            background-image: url(./icon/async.svg);
          }
          .async {
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            margin-right: 8px;
            background-image: url(./icon/async.svg);
            animation: turn 2s linear infinite;
          }
          .progress {
            font-family:PingFangSC-Medium;
            font-size:18px;
            white-space: nowrap;
            color:#2F343C;
          }
        }
        .operation {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          .close {
            width: 18px;
            height: 18px;
            background-size: 18px 18px;
            margin-left: 24px;
            background-image: url(./icon/close.svg);
          }
          .pointer {
            cursor: pointer;
            &:hover {
              background-image: url(./icon/close-hover.svg);
            }
          }
          .not-allowed {
            cursor: not-allowed;
          }
          .expand{
            width: 18px;
            height: 18px;
            background-size: 18px 18px;
            background-image: url(./icon/arror.svg);
            transform: rotate(180deg);
            -webkit-transition: transform .25s linear;
            -moz-transition: transform .25s linear;
            -o-transition: transform .25s linear;
            transition: transform .25s linear;
            cursor: pointer;
            &:hover {
              background-image: url(./icon/arror-hover.svg);
            }
          }
          .minimize{
            width: 18px;
            height: 18px;
            background-size: 18px 18px;
            background-image: url(./icon/arror.svg);
            cursor: pointer;
            -webkit-transition: transform .25s linear;
            -moz-transition: transform .25s linear;
            -o-transition: transform .25s linear;
            transition: transform .25s linear;
            &:hover {
              background-image: url(./icon/arror-hover.svg);
            }
          }
        }
      }
      .progress-line {
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        .cooper-list-header-progress {
          position: absolute;
          left: 0;
          bottom: 0;
          div {
            line-height: 2px !important;
            height: 2px !important;
          }
          .ant-progress-outer {
            display: block;
            .ant-progress-inner {
              position: absolute;
              left: 0;
              bottom: 0;
              .ant-progress-bg {
                background-color: #0066FF;
              }
            }
          }
        }
      }
    }
    .body-open {
      height: 260px;
      transition: height .25s linear;
      -webkit-transition: height .25s linear;
      -moz-transition: height .25s linear;
      -o-transition: height .25s linear;
    }
    .body-close {
      height: 0px;
      transition: height .25s linear;
      -webkit-transition: height .25s linear;
      -moz-transition: height .25s linear;
      -o-transition: height .25s linear;
    }
    .body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .loading-c {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 92px;
        .loading-c-img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          animation: turn 2s linear infinite;
        }
        .loading-c-text {
          font-size: 14px;
          color: #94A0B0;
        }
      }
      .list-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height:26px;
        background-color: #F5F6FA;
        border-top: 1px solid #e5e6ec;
        .header-btn {
          cursor: pointer;
          height:26px;
          font-size:12px;
          color: #0066FF;
          margin-right: 24px;
          line-height: 26px;
        }
      }
      .list-items {
        overflow: auto;
        height: 100%;
        flex-direction: column;
        width: 100%;
        .item-container {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 58px;
          border-bottom: 1px solid rgba(148,160,176,0.14);
          box-sizing: border-box;
          .title-container {
            padding: 0;
            padding-left: 27px;
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            height: 58px;
            img {
              width: 26px;
            }
            .text-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 19px;
              width: 1px;
              flex: 1;
              .name {
                font-size:14px;
                color:#2F343C;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .hint-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                .size {
                  font-size:12px;
                  color: #94A0B0;
                }
                .hint {
                  font-size:12px;
                  color:#ff3f5a;
                }
              }
            }
          }
          .operation {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            .hint {
              font-size:12px;
              color:#94A0B0;
            }
            .pause {
              background-size: 20px 20px;
              background-image: url(./icon/pause.svg);
              cursor: pointer;
              &:hover {
                background-image: url(./icon/pause-hover.svg);
              }
            }
            .continue {
              background-size: 20px 20px;
              background-image: url(./icon/continue.svg);
              cursor: pointer;
              &:hover {
                background-image: url(./icon/continue-hover.svg);
              }
            }
            .cancel {
              background-size: 20px 20px;
              background-image: url(./icon/cancel.svg);
              cursor: pointer;
              &:hover {
                background-image: url(./icon/cancel-hover.svg);
              }
            }
            .retry {
              background-size: 20px 20px;
              background-image: url(./icon/retry.svg);
              cursor: pointer;
              &:hover {
                background-image: url(./icon/retry-hover.svg);
              }
            }
            .jumpto {
              background-size: 20px 20px;
              background-image: url(./icon/jumpto.svg);
              cursor: pointer;
              &:hover {
                background-image: url(./icon/jumpto-hover.svg);
              }
            }
            .failed {
              background-size: 20px 20px;
              background-image: url(./icon/failed.svg);
              cursor: pointer;
              &:hover {
                background-image: url(./icon/retry.svg);
              }
            }
            .success {
              background-size: 20px 20px;
              background-image: url(./icon/success.svg);
            }
            .icon {
              width: 20px;
              height: 20px;
            }
            .one {
              margin-right: 8px;
            }
            .two {
              margin-right: 20px;
            }
            .three {
              // C9CFD7 0066FF
            }
          }
          .progress {
            width: 100%;
            height: 60px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }
        }
      }
    }
  }
}
.upload-big-file-tip {
  .ant-confirm-title {
    font-size: 20px;
    line-height: 26px;
  }
  .ant-confirm-content {
    font-size: 14px;
  }
}
