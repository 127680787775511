/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.aside-large-wrap {
  width: 240px;
  opacity: 1;
}
.aside-small-wrap {
  width: 64px;
  opacity: 1;
}
.aside {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.aside .aside-content {
  position: relative;
  margin-top: 20px;
  flex: 1;
}
.aside .aside-content-dc {
  margin-top: 9px;
}
.aside .item-name {
  width: 100%;
  color: #222A35;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
}
.aside .item-name-normal {
  padding: 12px 8px 12px 12px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.aside .item-name-normal:hover {
  background-color: #EBEEF1;
}
.aside .item-container {
  display: flex;
  align-items: center;
  width: auto;
}
.aside .item-small-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
}
.aside .item-small-container > i {
  padding-top: 4px;
}
.aside .item-small-container > span {
  width: 200%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 4px;
}
.aside .aside-icon {
  color: #6A707C;
}
.aside .item-text {
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.aside .item-name-active {
  color: #047FFE;
  background-color: #E5F2FE;
  font-weight: 500;
}
.aside .item-name-active .aside-icon {
  color: #047FFE;
}
.aside .item-name-active:hover {
  background-color: #E5F2FE;
}
.left-action {
  background-color: #F7F9FA;
  width: 240px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  margin-left: 22px;
}
.left-action-tenant {
  justify-content: flex-start;
}
.left-action-small {
  width: 64px;
  height: 145px;
  background-color: #F7F9FA;
  display: flex;
  flex-direction: column-reverse;
  padding: 19px 0;
  justify-content: flex-start;
  margin-left: 0;
}
.icon-arrow-right {
  margin-left: 2px;
  color: rgba(34, 42, 53, 0.3);
  font-size: 12px;
}
.icon-aside-large {
  font-size: 18px;
  line-height: 20px;
}
.split-line {
  width: 100%;
  height: 1px;
  background: #EBEEF1;
  margin: 8px 0;
}
.more-title {
  font-size: 12px;
  margin-bottom: 8px;
  padding-left: 12px;
  color: rgba(34, 42, 53, 0.5);
}
