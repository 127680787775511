/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.header-top-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 540px;
  flex-grow: 0;
  flex-shrink: 0;
}
@media screen and (max-width: 1200px) {
  .header-top-left {
    flex-basis: 445px;
  }
}
@media screen and (max-width: 1020px) {
  .header-top-left {
    flex-basis: 360px;
  }
}
