/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.cooper-notify-popover .ant-popover-inner-content {
  padding: 0 !important;
}
.cooper-notify-popover .ant-popover-inner-content li {
  height: auto !important;
}
.cooper-notify-icon {
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
}
.cooper-notify-icon:hover {
  background: #EBEEF1;
}
.cooper-notify-icon .dk-icon-zhanneixin {
  font-size: 16px;
  color: #6A707C;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.notify-list-item-detail-desc .title-con {
  width: 87%;
  word-break: break-word;
}
.cooper-notify > img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 4px;
}
.cooper-notify-position {
  position: relative;
}
.cooper-notify-count {
  position: absolute;
  top: -6px;
  left: 10px;
  background-color: #ff563a;
  border: 1px solid #ffffff;
  color: #fff;
  display: inline-block;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  padding: 0 4px;
  z-index: 100;
}
.cooper-notify .notify-list {
  width: 400px;
  height: 435px;
  bottom: 32px;
  left: 0px;
  background-color: #fff;
  z-index: 999;
  text-align: left;
  border-radius: 8px;
}
.cooper-notify .notify-list.hide {
  display: none;
}
.cooper-notify .notify-list-box {
  width: 400px;
  border-radius: 8px;
}
.cooper-notify .notify-list-header {
  display: flex;
  line-height: 40px;
  padding: 0 16px;
  color: #8a93a8;
}
.cooper-notify .notify-list-header :nth-child(1) {
  margin-right: 40px;
}
.cooper-notify .notify-list-header-ceil {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.cooper-notify .notify-list-header-ceil::before {
  content: '';
  display: none;
  position: absolute;
  left: 50%;
  bottom: -1px;
  width: 14px;
  border-bottom: 1px solid #fff;
  transform: translateX(-50%);
}
.cooper-notify .notify-list-header-ceil::after {
  content: '';
  display: none;
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #e4e9f3;
  border-right: 1px solid #e4e9f3;
  transform: translateX(-50%) rotate(-45deg);
}
.cooper-notify .notify-list-header-ceil:not(.disabled):hover {
  font-weight: bold;
  color: #17233e;
}
.cooper-notify .notify-list-header-ceil.active {
  font-weight: bold;
  color: #17233e;
}
.cooper-notify .notify-list-header-ceil.active::after,
.cooper-notify .notify-list-header-ceil.active::before {
  display: block;
}
.cooper-notify .notify-list-header-ceil.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.cooper-notify .notify-list-header-allAsRead {
  background-color: #fff;
  border: none;
  margin-left: auto;
  cursor: pointer;
}
.cooper-notify .notify-list-header-allAsRead:hover {
  color: #0066FF;
}
.cooper-notify .notify-list-header-allAsRead:focus {
  outline: none;
}
.cooper-notify .notify-list-header-allAsRead.hide {
  display: none;
}
.cooper-notify .notify-list-body {
  overflow: hidden;
  width: 100%;
  height: 393px;
  border-top: 1px solid #e4e9f3;
  word-break: break-all;
}
.cooper-notify .notify-list-body-box {
  padding-top: 4px;
}
.cooper-notify .notify-list-body-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #bec5d2;
}
.cooper-notify .notify-list-body-empty-box {
  text-align: center;
}
.cooper-notify .notify-list-body-empty-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: cover;
}
.cooper-notify .notify-list-body-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.cooper-notify .notify-list-body-loading li {
  list-style: none;
}
.cooper-notify .notify-list .notify-list-item .notice-bg-tips {
  background: #fde093;
  padding: 4px 6px;
  border-radius: 2px;
  margin-top: 5px;
}
.cooper-notify .notify-list .notify-list-item .bold {
  font-weight: 600;
}
.cooper-notify .notify-list-item {
  padding: 0 16px;
}
.cooper-notify .notify-list-item:not(:first-child) .notify-list-item-box {
  border-top: 1px solid #e4e9f3;
}
.cooper-notify .notify-list-item:hover {
  background-color: rgba(63, 129, 255, 0.06) !important;
}
.cooper-notify .notify-list-item.unread {
  background-color: #e4e9f330;
}
.cooper-notify .notify-list-item-active {
  color: #0066FF;
}
.cooper-notify .notify-list-item-box {
  padding: 12px 0;
}
.cooper-notify .notify-list-item-main {
  display: flex;
}
.cooper-notify .notify-list-item-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
}
.cooper-notify .notify-list-item-avatar img {
  position: relative;
  width: 100%;
  min-height: 100%;
  font-size: 0;
}
.cooper-notify .notify-list-item-avatar img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
}
.cooper-notify .notify-list-item-detail {
  flex: 1;
  margin-left: 8px;
  font-size: 12px;
}
.cooper-notify .notify-list-item-detail-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  line-height: 16px;
  color: #8a93a8;
}
.cooper-notify .notify-list-item-detail-info-left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  white-space: nowrap;
  max-width: 300px;
}
.cooper-notify .notify-list-item-detail-desc {
  line-height: 16px;
}
.cooper-notify .notify-list-item-detail-name {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  white-space: nowrap;
  max-width: 120px;
}
.cooper-notify .notify-list-item-detail-name a {
  color: inherit;
}
.cooper-notify .notify-list-item-detail-department {
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  white-space: nowrap;
}
.cooper-notify .notify-list-item-detail-time {
  margin-left: 10px;
  flex: 0 0 auto;
}
.cooper-notify .notify-list-item-detail-text-area {
  margin: 8px 0 22px 0;
  border-radius: 2px;
}
.cooper-notify .notify-list-item-detail-text-area > textarea {
  font-size: 14px;
  border-radius: 2px;
  background-color: rgba(34, 42, 53, 0.06) !important;
}
.cooper-notify .notify-list-item-access-setting {
  margin-top: 8px;
  line-height: 16px;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 20px;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-inner {
  border-color: #BEC5D2;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.cooper-notify .notify-list-item-access-setting .ant-checkbox:hover .ant-checkbox-inner,
.cooper-notify .notify-list-item-access-setting .ant-checkbox-input:focus + .ant-checkbox-inner,
.cooper-notify .notify-list-item-access-setting .ant-checkbox-checked .ant-checkbox-inner,
.cooper-notify .notify-list-item-access-setting .ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #0066FF;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-checked .ant-checkbox-inner,
.cooper-notify .notify-list-item-access-setting .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #0066FF;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox + span {
  padding-left: 4px;
  padding-right: 0;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-inner {
  border-radius: 50%;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-checked::after {
  border-radius: 50%;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-disabled + span {
  color: #8A93A8;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-disabled .ant-checkbox-inner {
  border-color: #BEC5D2 !important;
  background-color: #fff;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #BEC5D2;
}
.cooper-notify .notify-list-item-access-setting .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
}
.cooper-notify .notify-list-item-btns {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
}
.cooper-notify .notify-list-item-btns a {
  border-radius: 4px;
  font-size: 12px;
}
.cooper-notify .notify-list-item-reject {
  padding: 1px 15px;
  border: 1px solid #e4e9f3;
  background-color: #fff;
  transition: all 0.3s;
}
.cooper-notify .notify-list-item-reject:hover {
  color: #047FFE;
  border-color: #047FFE;
}
.cooper-notify .notify-list-item-accept {
  padding: 2px 16px;
  background-color: #047FFE;
  color: #fff;
  margin-left: 10px;
  transition: all 0.3s;
}
.cooper-notify .notify-list-item-accept:hover {
  color: #fff;
  background-color: #047FFE;
}
.cooper-notify .notify-list-item-status {
  line-height: 24px;
  font-size: 12px;
  color: #8a93a8;
}
.cooper-notify .notify-list-loading {
  padding: 10px 0;
  text-align: center;
  color: #8a93a8;
}
.cooper-notify .notify-list-loading img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}
.cooper-notify .notify-list-loading span {
  margin-left: 4px;
  vertical-align: middle;
}
.notify-list-small .notify-list {
  left: 43px;
  bottom: 10px;
}
.notify-list-small .notify-list::before {
  width: 25px;
  height: 80px;
  left: -25px;
}
.notify-list-small {
  line-height: 40px;
  text-align: center;
  margin-right: 0px;
  margin-top: 5px;
}
.notify-list-small .dk-icon-zhanneixin {
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.notify-list-small > span {
  left: 18px;
  top: 2px;
}
.notify-list-loading {
  justify-content: center;
}
.notify-list-item-bold-name {
  font-weight: 600;
  color: #222A35;
}
