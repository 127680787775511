/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.layout {
  display: flex;
  height: 100%;
  position: relative;
  flex: 1;
  overflow: hidden;
}
.layout .left-wrap {
  position: relative;
  display: flex;
  height: 100%;
  z-index: 106;
}
.layout .left-wrap:hover .aside-operate {
  visibility: inherit;
}
.layout .left-wrap .left {
  height: 100%;
  background-color: #F7F9FA;
  position: relative;
  display: flex;
  transition: all 2s ease;
  overflow-x: hidden;
  overflow-y: overlay;
  overflow-y: auto;
}
.layout .left-wrap .aside-btn-handle {
  width: 20px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 0;
  background-color: transparent;
}
.layout .left-wrap .aside-btn-handle:hover .aside-operate {
  visibility: inherit;
}
.layout .right {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.layout .right .body-wrap {
  flex: 1;
  overflow-y: hidden;
  min-width: 745px;
  position: relative;
}
.aside-operate {
  width: 14px;
  height: 56px;
  border-radius: 64px;
  display: flex;
  border: 1px solid #EBEEF1;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 69px;
  right: 8px;
  background-color: #FFF;
  z-index: 999;
  cursor: pointer;
}
.aside-operate .operate-icon {
  color: rgba(34, 42, 53, 0.3);
  font-size: 14px;
}
.aside-operate:hover .operate-icon {
  color: rgba(34, 42, 53, 0.8);
}
.isZhankai .operate-icon {
  transform: rotateY(180deg);
}
