/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/** mixin **/
.space-content-wrap {
  width: 100%;
  font-size: 13px;
  line-height: 21px;
}
.space-content-wrap .space-item {
  width: 100%;
  color: #222A35;
  margin-bottom: 2px;
}
.space-content-wrap .space-item:hover .icon-pin {
  display: flex !important;
}
.space-content-wrap .space-item .wrap {
  width: 100%;
}
.space-content-wrap .space-item .space-item-normal {
  padding: 7px 12px;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
}
.space-content-wrap .space-item .space-item-normal:hover {
  background-color: rgba(34, 42, 53, 0.06);
}
.space-content-wrap .space-item .space-item-normal:hover .icon-pin {
  display: block;
}
.space-content-wrap .space-item .space-item-normal:hover .icon-dian {
  color: rgba(34, 42, 53, 0.3) !important;
}
.space-content-wrap .space-item .space-item-normal .content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.space-content-wrap .space-item .space-item-normal .content-wrap .left-wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.space-content-wrap .space-item .space-item-normal .content-wrap .left-wrap .icon-dian {
  font-size: 20px;
  line-height: 14px;
  color: #D8DEE3;
}
.space-content-wrap .space-item .space-item-normal .content-wrap .left-wrap .item-text {
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.space-content-wrap .space-item .space-item-normal .content-wrap .icon-pin {
  display: none;
  font-size: 16px;
  line-height: 16px;
  padding: 2px;
  color: #6A707C;
  border-radius: 4px;
}
.space-content-wrap .space-item .space-item-normal .content-wrap .icon-pin:hover {
  background-color: #D8DEE3;
}
.space-content-wrap .space-item .space-item-active {
  color: #047FFE;
  background-color: #E5F2FE;
  font-weight: 500;
}
.space-content-wrap .space-item .space-item-active .icon-dian {
  color: #047FFE !important;
}
.space-content-wrap .space-item .space-item-active:hover {
  color: #047FFE;
  background-color: #E5F2FE;
  font-weight: 500;
}
.space-content-wrap .space-item .space-item-active:hover .icon-dian {
  color: #047FFE !important;
}
@media screen and (max-height: 726px) {
  .space-content-wrap {
    max-height: 167px !important;
  }
}
/** scrollbar for windows,mac and others**/
:global .windows .space-content-wrap-os-flag {
  overflow-y: hidden;
}
:global .windows .space-content-wrap-os-flag:hover {
  overflow-y: auto;
}
:global .mac .space-content-wrap-os-flag,
:global .linux .space-content-wrap-os-flag,
:global .generic .space-content-wrap-os-flag {
  overflow-y: auto;
}
